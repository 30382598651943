import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationConstant } from 'src/app/shared/constant/app.constant';
import { FormService } from '../../services/form.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationNotifierService } from 'src/app/shared/component/application-notifier/application-notifier.service';
import { ApplicationNotifierConstant } from 'src/app/shared/constant/notifier.constant';
import { CommonService } from '../../services/common.service';
import { AppUtil } from 'src/app/shared/utils/app.util';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  applicationConstant = ApplicationConstant;
  autoFetchedEmail: any;
  applicationNotifierConstant = ApplicationNotifierConstant;
  errorMessage: string;
  isResetFormValid = true;
  keyToken: any;
  responseResetForm: FormGroup;
  successMessage: string;

  constructor(private activatedRoute: ActivatedRoute,
              private applicationNotifierService: ApplicationNotifierService,
              public commonService: CommonService,
              private formBuilder: FormBuilder,
              private formService: FormService,
              private router: Router) {
    this.keyToken = this.router.url.split('/').pop();
  }

  ngOnInit(): void {
    this.getAutoFetchEmail();
    this.responseResetForm = this.formBuilder.group({
      key: [this.keyToken],
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4)]],
      email: ['', [Validators.required]],
      termAndCondition: [false, Validators.requiredTrue],
    });
  }

  /**
   * Reset form data submit
   *
   * @param form
   */
  resetPassword(form) {
    if (form.valid) {
      this.isResetFormValid = true;
      if (form.get('confirmPassword').value === form.get('password').value) {
        this.formService.saveDetails(`${this.applicationConstant.endpoint.RESET_PASSWORD_URL}`,
            this.responseResetForm.value).subscribe(data => {
              this.successMessage = data.message;
              this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.password_updated);
              this.commonService.navigateOLDLMSLink(ApplicationConstant.externalPages.LOGIN);
            },
            (err) => {
              if (err.error.message) {
                this.errorMessage = err.error.message;
                this.isResetFormValid = false;
                this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.invalid_email);
              }
            }
          );
      } else {
        this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.password_not_matched);
      }
    } else {
      this.isResetFormValid = false;
    }
  }

  /* Auto fetch email id */
  getAutoFetchEmail() {
    this.formService.getDetails(`${this.applicationConstant.endpoint.GET_AUTOFETCH_EMAIL}/${this.keyToken}`).subscribe((data) => {
      this.autoFetchedEmail = data;
      this.responseResetForm.patchValue({
        email: data.data
      });
    });
  }

  /**
   * Navigate to External link.
   *
   */
   navigateWebsiteLink() {
   return AppUtil.createWebsiteURL(this.applicationConstant.externalPages.TERMS);
  }

}
