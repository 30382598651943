<div *ngIf="showCourses">
  <div class="all-courses">
    <div class="sections container">
      <div class="section-item">
        <h1 class="ht-4">Most Popular Online Specialisations</h1>
        <ul>
          <li *ngFor="let course of popularCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>

      <div class="section-item">
        <h1 class="ht-4">Trending Online</h1>
        <ul>
          <li *ngFor="let course of trendingCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>

      <div class="section-item">
        <h1 class="ht-4">Top Universities Online Certificates</h1>
        <ul>
          <li *ngFor="let course of topUniversitiesCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>

      <div class="section-item">
        <h1 class="ht-4">Accredited Online Degree Program</h1>
        <ul>
          <li *ngFor="let course of degreeProgramCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<footer class="footer container-fluid p-0">
  <div class="container pt-3">
    <div class="new-footer col-md-12 row">
      <div class="col-md-4 mb-4">
        <div class="subscribe">
          <div class="col-md-12 row">
            <img alt="athena logo"
                 src="assets/athena-logo.svg"
                 class="img-fluid pb-3">
          </div>
          <div class="col-md-12">
            <p class="athena-des-footer">
              Athena Global Education is an Online Education provider offering self-paced Masters,
              Doctorate and Microcredit programs in collaboration with European Universities and
              Reputed Professional Qualifications Authority. Athena is the latest venture of
              Westford Education Group, a higher education services provider since 2009.
            </p>
          </div>
          <div id="newsletter-info-box"
               class="alert alert-info"
               style="display:none">
          </div>
          <div id="newsletter-info-box-error"
               class="alert alert-danger"
               style="display:none">

          </div>
          <span class="subscribe-newsletter">
            Subscribe to our newsletter
          </span>
          <div class="news-letter">
            <input type="email"
                   aria-label="email id"
                   id="newsletter-email-input"
                   class="form-control">
          </div>
          <button type="button"
                  id="news-letter-subscribe"
                  class="btn btn-sm mt-3 btn-outline violet-btn subscribe-button">
            Subscribe
          </button>
        </div>
      </div>

      <div class="col-md-8 mb-4">
        <!--Footer Links: START-->
        <div class="row links">
          <div class="col-md-4 hideinMobileView">
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}about-us/our-background">
                About Us
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}about-us/athena-governance-structure">
                Governance
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}about-us/policies-and-procedures">
                Policies & Procedures
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}about-us/board-of-governance">
                Board of Governance
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}about-us/academic-council">
                Academic Council
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}about-us/executive-council">
                Executive Council
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}about-us/core-principles">
                Core Principles
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}Contact-Us">
                Contact Us
              </a>
            </p>
          </div>
          <div class="col-md-4 hideinMobileView">
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}privacy-policy">
                Privacy Policy
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}registration-terms">
                Terms & Conditions
              </a>
            </p>
            <p>
              <a target="_blank"
                href="{{ websiteURL }}sites/default/files/pdf-files/Policies/ACCREDITATION_OF_PRIOR_LEARNING.pdf">
                APL policy
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}sites/default/files/pdf-files/Policies/Modern-Slavery-Policy.pdf">
                Modern Slavery Policy
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}policies/equality_and_diversity_policy">
                Equality and Diversity Policy
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}feeAndCharges">
                Other Fee & Charges
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="{{ websiteURL }}certificateIssuance">
                Certificate Issuance
              </a>
            </p>
          </div>
          <div class="col-md-4">
            <div class="subscribe">
              <div class="col-md-12 row">
                <img alt="athena logo"
                     src="assets/westford-reg.png"
                     class="img-fluid pb-3 westford-logo">
                <p style="left:auto;"
                   class="athena-des-footer">
                  Westford Education Group (WEG) is a leading provider of accredited international education to
                  aspiring learners across the globe. Westford is fast emerging as a reputed brand of global
                  education providers.
                </p>
              </div>
              <div class="links mb-4 hideinMobileView">
                <div style="top:25%; position: relative;">
                  <div class="social-icons mb-3">
                    <a href="https://www.facebook.com/AthenaGlobalEducation/"
                       target="_blank">
                      <img alt="facebook icon"
                           src="assets/facebook.svg">
                    </a>
                    <a href="https://www.instagram.com/age_athena/"
                       target="_blank">
                      <img alt="instagram icon"
                           src="assets/instagram.svg">
                    </a>
                    <a href="https://www.linkedin.com/school/athenaglobaleducation/"
                       target="_blank">
                      <img alt="linkedin icon"
                           src="assets/linkedin.svg">
                    </a>
                    <a href="https://twitter.com/AthenaGlobalEdu/"
                       target="_blank">
                      <img alt="twitter icon" src="assets/twitter.svg">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Footer Links: END-->
        <div class="row address">
          
          <div class="col-md-4">
            <p>
              <strong>UK</strong><br />
              Athena Global Education LTD<br />
              The Sherrington Building, Magdalen<br />
              Centre,<br />
              The Oxford Science Park, Oxford OX4<br />
              4GA. UK<br />
              Phone: +44 186 578 4545
            </p>
          </div>
          <div class="col-md-4">
            <p>
              <strong>MIDDLE EAST</strong><br />
              Athena Global Education FZE<br />
              Block L-3, First Floor,<br />
              P O Box 519265, Sharjah Publishing City,<br />
              Free Zone, Sharjah, UAE<br />
              Phone : +971 65 31 2511
            </p>
          </div>
          <div class="col-md-4">
            <p>
              <strong>INDIA</strong><br />
              Uniathena Private Limited<br />
              9A, Midas Tower, Phase 1,<br />
              Hinjewadi Rajiv Gandhi Infotech Park<br />
              Pune - 411057<br />
              Phone: +91 9145665544
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-2 links col-6 mb-4 hideinMobileView">
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    All Copyrights reserved @ Athena Global Education {{ this.currentYear }}
  </div>
</footer>
