<div class="ck-editor-open-page">
    <app-ck-editor [(ngModel)]="commentInput"
                (ngModelChange)="validateComment()"
                [showCkEditorAndriodWeb]="true"></app-ck-editor>
    <p class="error m-0" *ngIf="showErrorMessage">{{ commentType === 'post' ? 'Post' : 'Reply' }} cannot be empty!!</p>
    <div class="email-updates">
        <input type="checkbox" 
               name="email_updates"
               class="form-control pointer"
               [(ngModel)]="enableFaculty"/>
        <label class="pointer" for="email_updates">
            Request Tutor Reply for your Query
        </label><br>
    </div>
    <div class="post-button" (click)="addComment()">
        <button>Add {{ commentType === 'post' ? 'Post' : 'Reply' }}</button>
    </div>
</div>