<h2 mat-dialog-title *ngIf="data?.title">
  {{data?.title}}
</h2>
<mat-dialog-content *ngIf="data"
                    class="mat-typography">
  <ckeditor [(ngModel)]="data.ckEditorModel"
            #ckEditor="ngModel"
            (ready)="onCkEditorReady($event)"
            [editor]="data?.editor"
            name="ckEditor"
            [config]="data?.config"
            id="modalCkEditor">
  </ckeditor>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="ath-action">
    <div class="ath-button">
      <button mat-button class="ok-button text-center" [mat-dialog-close]="data.ckEditorModel">
        Save
      </button>
    </div>
    <div class="ath-button cancel-button">
      <button mat-button class="ok-button text-center" mat-dialog-close>
        Close
      </button>
    </div>
  </div>
</mat-dialog-actions>
