<div class="reset-body">
  <div class="name">
    <h1>Change Password</h1>
    <div mat-dialog-close class="mat-close">
      <span  class="material-icons">
        close
      </span>
    </div>
  </div>
  <form [formGroup]="changePasswordForm"
        (ngSubmit)="doUpdatePassword(changePasswordForm)">
    <div class="reset-form">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <mat-form-field appearance="blank">
            <label>Current Password</label>
            <input matInput
                   formControlName="oldPassword"
                   type="text"
                   required/>
          </mat-form-field>
        </div>
        <span *ngIf="changePasswordForm.get('oldPassword').value == '' && !IsResetFormValid"
              class="error-block error-message">Please enter current password</span>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <mat-form-field appearance="blank">
            <label>New Password</label>
            <input matInput
                   formControlName="newPassword"
                   type="text"
                   required/>
          </mat-form-field>
        </div>
        <span *ngIf="changePasswordForm.get('newPassword').value == '' && !IsResetFormValid"
              class="error-block error-message">Please enter new password</span>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <mat-form-field appearance="blank">
            <label>Confirm Password</label>
            <input matInput
                   formControlName="confirmPassword"
                   type="text"
                   required/>
          </mat-form-field>
        </div>
        <span *ngIf="changePasswordForm.get('confirmPassword').value == '' && !IsResetFormValid"
              class="error-block error-message">Please confirm new password </span>
      </div>
      <div class="item-editor">
        <div class="ath-button">
          <button>Update Password</button>
        </div>
      </div>
    </div>
  </form>
</div>
