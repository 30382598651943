import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-application-loader',
  templateUrl: './application-loader.component.html',
  styleUrls: ['./application-loader.component.scss']
})
export class ApplicationLoaderComponent implements OnInit {

  isLoading = false;
  
  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe(res => {
      this.isLoading = res;
    }, err => {
      console.error('err', err);
    });
  }

}
