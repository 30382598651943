import {ApplicationConstant} from '../shared/constant/app.constant';
import {AppUtil} from '../shared/utils/app.util';
import {CkEditorConstant} from '../shared/constant/ck-editor.constant';

/**
 * Ck-Editor Adaptor for image upload file.
 */
export class UploadAdapterService {

  private formService;
  private loader;

  constructor(loader, formService) {
    this.loader = loader;
    this.formService = formService;
  }

  public upload(): Promise<any> {
    return this.readUploadFile();
  }

  /**
   * Read the upload file.
   */
  readUploadFile(): Promise<any> {
    return this.loader.file.then(imageFile => new Promise((resolve, reject) => {
      if (AppUtil.checkFileSize(imageFile, CkEditorConstant.maxFileSize)) {
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = (e) => {
          this.uploadFile(fileReader.result)
            .then(value => {
              resolve({ default: value });
            })
            .catch(reason => reject(reason));
        };
        fileReader.readAsDataURL(imageFile);
      } else {
        reject(CkEditorConstant.maxFileErrorMessage);
      }
    }));
  }

  /**
   * Upload the file
   * @param data file data
   */
  uploadFile(data: any) {

    return new Promise((resp, err) => {
      const formData = new FormData();
      formData.append('image', data);
      this.formService.saveDetails(ApplicationConstant.endpoint.UPLOAD_IMAGE_URL, formData)
        .subscribe(res => {
          resp(res.data);
        }, error => {
          console.log('error', error);
          err(error);
        });
    });
  }
}
