import { Injectable } from '@angular/core';
import {ApplicationNotifierComponent} from './application-notifier.component';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {NotifierData} from './notifier-data.interface';
import {ApplicationNotifierConstant} from '../../constant/notifier.constant';

@Injectable({
  providedIn: 'root'
})
export class ApplicationNotifierService {

  constructor(private snackBar: MatSnackBar) { }

  /**
   * Get the notifier.
   * @param data for the notifier data to be shown
   */
  getNotifier(data: NotifierData) {
    const notifierConfig = ApplicationNotifierConstant.notifierConfig;
    notifierConfig[`data`] = data;
    this.snackBar.openFromComponent(ApplicationNotifierComponent, notifierConfig as MatSnackBarConfig);
  }

  /**
   * Create the dynamic notifier messages.
   * @param data message to show
   * @param validExtensions valid extensions for file upload
   * @return NotifierData
   */
  createNotifierMessage(data: NotifierData, validExtensions: Array<string>): NotifierData {
    const notifier = Object.assign({}, data);
    notifier.message = notifier.message + validExtensions.toString();
    return notifier;
  }

}
