import { Injectable } from '@angular/core';

import { BaseService } from '../services/base.service';
import { ApplicationConstant } from '../shared/constant/app.constant';

@Injectable({
  providedIn: 'root'
})
export class DiscussionForumService {
  constructor(private baseService: BaseService) {
  }

  moderateThread(data: any) {
    return this.baseService.post(`${ApplicationConstant.endpoint.MODERATION_URL}`, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getUserDiscussions(catId: number, uid: number) {
    return this.baseService.get(`${ApplicationConstant.endpoint.DISCUSSION_URL}?uid=${uid}&sort=latest&filter=&catid=${catId}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getMyDiscussions(uid: any) {
    return this.baseService.get(`${ApplicationConstant.endpoint.MY_DISCUSSION_URL}?uid=${uid}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  threadViews(data: any) {
    return this.baseService.post(`${ApplicationConstant.endpoint.DISCUSSION_VIEW_URL}`, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  likeContentInDiscussion(data: any) {
    return this.baseService.post(`${ApplicationConstant.endpoint.DISCUSSION_RATING_URL}`, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getAutoSuggestion(data: any) {
    return this.baseService.get(`${ApplicationConstant.endpoint.AUTO_SEARCH_URL}?uid=${data.uid}&searchq=${data.searchq}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getUserData(id: number) {
    return this.baseService.get(`${ApplicationConstant.endpoint.USER_DETAILS_URL}/${id}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getProfileImage(id: number) {
    return this.baseService.get(`${ApplicationConstant.endpoint.USER_PROFILE_IMAGE_URL}?uid=${id}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getParameterByName(paramname: any) {
    const url = window.location.href;
    const name = paramname.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getCategory() {
    return this.baseService.get(`${ApplicationConstant.endpoint.CATEGORY_URL}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getDiscussionThreads(params: string, params2: string, params3: number, params4: any, params5: string) {
    let route = 'discussion';
    if (params5 === 'admin') {
      route = 'alldiscussions';
    }
    return this.baseService.get(`${route}?sort=${params}&filter=${params2}&page=${params3}&catid=${params4}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  createNewPost(data: any) {
    return this.baseService.post(`${ApplicationConstant.endpoint.DISCUSSION_POST_URL}`, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  createNewReply(data: any) {
    return this.baseService.post(`${ApplicationConstant.endpoint.DISCUSSION_REPLY_URL}`, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  createCategory(data: any) {
    return this.baseService.post(`${ApplicationConstant.endpoint.CATEGORY_URL}`, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getRepliesAndPost(id: number) {
    return this.baseService.get(`${ApplicationConstant.endpoint.DISCUSSION_REPLY_URL}/${id}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getAllCourses() {
    return this.baseService.get(`${ApplicationConstant.endpoint.COURSE_URL}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getSingleDiscussion(id: number) {
    return this.baseService.get(`${ApplicationConstant.endpoint.DISCUSSION_URL}/${id}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  createNewDiscussion(data: any) {
    return this.baseService.post(`${ApplicationConstant.endpoint.DISCUSSION_URL}`, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  /**
   * Post or reply for the discussion.
   * @param url
   */
  getPostOrReply(url: string) {
    return this.baseService.get(url);
  }

}
