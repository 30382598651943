/**
 * Dev Environment properties file.
 */
export const environment = {
  production: false
};

/**
 * Application URLs.
 */
export const APP_URL = {
  API: 'https://newlms.uniathena.com/athenadev/api/',
  API_NEW: 'https://lmsapidev.uniathena.com/api/',
  IP_GEOLOCATION: 'https://extreme-ip-lookup.com/json/?key=Q2XTHdI7x9BFavSEj8pj',
  OLD_LMS: 'https://ulearn.schneidestaging.in/',
  WEBSITE: 'https://websitestg.uniathena.com/',
  NEW_LMS: 'https://dev.uniathena.com/lms/',
  ACADS_API: 'https://athenaapi.schneidestaging.in/',
  WEBSITE_ENCRYPT_API: 'https://athenaapi.uniathena.com/Encript/GetEncriptValue/',
  BASE_HREF_URL: 'https://dev.uniathena.com'
};

/**
 * Application configurations.
 */
export const APP_CONFIGURATION = {
  ADMIN: 24,
  CATEGORY_ID: 74,
  DISCUSSION_BOARD_ADMIN: 22,
  GUEST: 6
};

/**
 * Social login client Ids.
 */
 export const SOCIAL_LOGIN = {
  GOOGLE_PROVIDER_ID: '276645629606-l0lte3d7aag43kmsm8g339rhhrhaev2o.apps.googleusercontent.com',
  FACEBOOK_PROVIDER_ID: '340564521258908',
  MICROSOFT_CLIENT_ID: 'e9314991-e6e5-42e2-963f-f47461987334'
};