import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { RoutingConstant } from './shared/constant/routing.constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'athena-frontend';
  currentURL: string;
  isHeaderFooterHidden = false;

  constructor(private commonService: CommonService,
              private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
        this.commonService.currentUrl = event.url;
        this.isHeaderFooterHidden = this.currentURL && this.currentURL.indexOf(RoutingConstant.CK_EDITOR_PAGE) > -1;
      }
    });
  }
}
