import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CkEditorService } from '../ck-editor.service';

@Component({
  selector: 'app-ck-editor-modal',
  templateUrl: './ck-editor-modal.component.html',
  styleUrls: ['./ck-editor-modal.component.scss']
})
export class CkEditorModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private ckEditorService: CkEditorService) {
  }

  ngOnInit(): void {
  }

  /**
   * Read the uploaded content
   * @param eventData event
   */
  onCkEditorReady(eventData) {
    eventData.setData( this.data.ckEditorModel );
    this.ckEditorService.customUploadAdapterPlugin(eventData);
  }

}
